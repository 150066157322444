import React from 'react'
import './About.css';
import { motion } from 'framer-motion';
import AboutImage from '../../assets/images/hero-banner.png';

const About = () => {
  return (
    <div className="about-container">
      <div className='about-header'>
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className='about-title'
        >
          About Us
        </motion.h1>
      </div>
      <div className="about-box">
        <motion.div className='about-left-container'
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <img src={AboutImage} alt="AboutImg" />
        </motion.div>
        <motion.div className='about-right-container'
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
        >
          <p>
            Welcome to <b>Poppers Tea</b>, where the <b>art of tea</b> meets a cozy haven for <b>tea enthusiasts</b>! Our journey began with a deep love for the rich traditions and diverse flavors of tea. At <b>Poppers Tea</b>, we are more than just a tea shop; we are curators of unique tea experiences. We have served a variety of flavors in the <b>tea</b> with fresh <b>Boba</b>. You get high-quality tea at poppers tea.
          </p>
          <p>
            Immerse yourself in the world of <b>Poppers Tea</b>. Visit our <b>shop</b>, and explore our diverse <b>tea menu</b>. Thank you for choosing <b>Poppers Tea</b> - where every cup tells a story, and every visit feels like coming home.
          </p>
        </motion.div>
      </div>
    </div>
  )
}

export default About;