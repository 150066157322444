import React from 'react';
import DoodleDrink from '../../../assets/images/doodle-drink-2.png';
import './MenuCardTwo.css';
import { motion } from 'framer-motion';

const MenuCardTwo = ({ title, image }) => {
    return (
        <div>
            <div className="menu-card">
                <div className='menu-card-image'>
                    <img src={image} alt="Drink1" />
                </div>
                <div className="menu-card-details">
                    <div className='menu-card-doodle'>
                        <img src={DoodleDrink} alt="Doodle Drink" />
                    </div>
                    <h3 className="menu-card-title">{title}</h3>
                </div>
            </div>
        </div>
    )
}

export default MenuCardTwo;