import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './components/layouts/Navbar/Navbar';
import Home from './pages/Home/Home';
import Menu from './pages/Menu/Menu';
import About from './pages/About/About'
import Contact from './pages/Contact/Contact';
import Location from './pages/Location/Location';
import Footer from './components/layouts/Footer/Footer';

const App = () => {
  return (
    <BrowserRouter>
      <div className='app'>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/menu' element={<Menu />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/location' element={<Location />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;