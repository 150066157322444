import React, { useEffect, useState } from 'react'
import './ReviewSection.css'
import { CustomerReview } from '../../data/data';
import Doddle from '../../assets/images/doodle-drink-3.png'
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from 'react-icons/md';
import { FaQuoteLeft, FaQuoteRight, FaStar } from "react-icons/fa";


const ReviewSection = () => {
    const [currentIdx, setCurrentIdx] = useState(0);
    const [transition, setTransition] = useState(false);

    const nextSlide = () => {
        setTransition(true);
        setTimeout(() => {
            setCurrentIdx((prevIndex) => (prevIndex + 1) % CustomerReview.length);
            setTransition(false);
        }, 500);
    };

    const prevSlide = () => {
        setTransition(true);
        setTimeout(() => {
            setCurrentIdx((prevIndex) => (prevIndex - 1 + CustomerReview.length) % CustomerReview.length);
            setTransition(false);
        }, 500);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide();
        }, 4000);

        return () => clearInterval(interval);
    }, []);
    return (
        <div className='review-section'>
            <h2 className="review-title">What Customer Says</h2>
            <div className='review-box'>
                <div className={`cust-box-middle ${transition ? 'fade-out-left' : ''}`}>
                    <div className='review-doddle'>
                        <img src={Doddle} alt="doddle" />
                    </div>

                    <div className={`cust-box-rating ${transition ? 'fade-out-right' : ''}`}>
                        <p>
                            {
                                CustomerReview[currentIdx].rating
                            }
                            <span><FaStar /></span>
                        </p>
                    </div>

                    <p className='review-name'>
                        {
                            CustomerReview[currentIdx].name
                        }
                    </p>

                    <p className='cust-msg'><span><FaQuoteLeft /></span>{CustomerReview[currentIdx].message}<span><FaQuoteRight /></span></p>
                </div>

                <div className='cust-box-right'>
                    <button className='prev-btn cust-btn' onClick={prevSlide}><MdOutlineKeyboardDoubleArrowLeft /></button>
                    <button className='next-btn cust-btn' onClick={nextSlide}><MdOutlineKeyboardDoubleArrowRight /></button>
                </div>
            </div>
        </div>
    )
}

export default ReviewSection