import React from 'react';
import './MenuCard.css';
import { motion } from 'framer-motion';

const MenuCard = ({ title, image }) => {
    return (
        <motion.div className="drink-card">
            <div className='drink-image'>
                <img src={image} alt="Drink1" />
            </div>
        </motion.div>
    )
}

export default MenuCard;