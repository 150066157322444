import React from 'react';
import './Contact.css';
import { motion } from 'framer-motion';
import ContactImg from '../../assets/images/contact_img.jpeg';

const Contact = () => {

    return (
        <div className='contact-container'>
            <div className='contact-header'>
                {/* <motion.img
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    src={TaroSmoothie} alt="Coffee Drink"
                    className="contact-header-image"
                /> */}
                <motion.h1
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    className='contact-title'
                >
                    Contact Us
                </motion.h1>
            </div>
            <motion.div className='contact-box'
            >
                <motion.div className='contact-left'
                    initial={{ y: -30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.5, duration: 1 }}
                >
                    <img src={ContactImg} alt="Signature Coffee" />
                </motion.div>
                <div className='contact-right'>
                    <motion.div className='contact-number'
                        initial={{ y: 100, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.5, duration: 1 }}
                    >
                        <h2>+1 872-201-4384</h2>
                    </motion.div>
                    <motion.div className='contact-email'
                        initial={{ y: 100, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 1, duration: 1 }}
                    >
                        <h2>Infopopperstea@gmail.com</h2>
                    </motion.div>
                </div>
            </motion.div>
        </div>
    )
}

export default Contact;