import React from 'react';
import './Footer.css';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section">
                    <h2>Follow Us</h2>
                    <div className="social-icons">
                        <a href="https://www.facebook.com/profile.php?id=100078507050772"><FaFacebook /></a>
                        <a href="https://twitter.com"><FaTwitter /></a>
                        <a href="https://www.instagram.com"><FaInstagram /></a>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 Popper's Tea. All rights reserved.</p>
            </div>
        </footer >
    );
}

export default Footer;