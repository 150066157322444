import React from 'react';
import './Menu.css';
import Menu1 from '../../assets/images/drinksMenu.jpg';
import Menu2 from '../../assets/images/drinksMenu2.jpg';
import { motion } from 'framer-motion';
import MenuCardTwo from '../../components/customcomponents/MenuCardTwo/MenuCardTwo';
import MenuCard from '../../components/customcomponents/MenuCard/MenuCard';
import { MenuData, Toppings } from '../../data/data';

const Menu = () => {

    const listVariants = {
        initial: {
            y: -30,
            opacity: 0
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
                duration: 0.5,
                ease: 'linear'
            },
        }
    }

    return (
        <div className='menu-container'>
            <div className="menu-header">
                <motion.h1
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    className='menu-title'
                >
                    Our Menu
                </motion.h1>
            </div>
            <div className='popular-drinks-container'>
                <motion.div className='popular-drinks-cards'
                    variants={listVariants}
                    initial="initial"
                    whileInView="animate"
                >
                    {
                        Toppings.map((item) => (
                            <motion.div
                                variants={listVariants}
                            >
                                <MenuCard
                                    key={item.id}
                                    title={item.name}
                                    image={item.image}
                                />
                            </motion.div>
                        ))
                    }
                </motion.div>
            </div>
            <div className='menu-item'>
                <motion.img
                    src={Menu1} alt="Menu 1"
                    className='menu-image'

                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.5, duration: 1 }}
                />
            </div>
            <div className='menu-item'>
                <motion.img
                    src={Menu2} alt="Menu 2"
                    className='menu-image'

                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 1, duration: 1 }}
                />
            </div>

            <div className='popular-drinks-container'>
                <h2 className="popular-drinks-title">Our Popular Drinks</h2>
                <motion.div className='popular-drinks-cards'
                    variants={listVariants}
                    initial="initial"
                    whileInView="animate"
                >
                    {
                        MenuData.map((item) => (
                            <motion.div
                                variants={listVariants}
                            >
                                <MenuCardTwo
                                    key={item.id}
                                    title={item.title}
                                    image={item.img}
                                />
                            </motion.div>
                        ))
                    }
                </motion.div>
            </div>
        </div >
    )
}

export default Menu;