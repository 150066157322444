import React from 'react'
import { GiOrange, GiStrawberry, GiPeach, GiVanillaFlower, GiCutLemon } from "react-icons/gi";
import './Flavour.css'

const Flavour = ({ flv }) => {
    let Icon;
    let bgColor;
    let flvTitle;

    switch (flv) {
        case 'strawberry':
            Icon = <GiStrawberry />;
            bgColor = 'flvStrawberry'
            flvTitle = 'Strawberry'
            break;
        case 'lemon':
            Icon = <GiCutLemon />;
            bgColor = 'flvLemon'
            flvTitle = 'Lemon'
            break;
        case 'orange':
            Icon = <GiOrange />;
            bgColor = 'flvOrange'
            flvTitle = 'Orange'
            break;
        case 'peach':
            Icon = <GiPeach />;
            bgColor = 'flvPeach'
            flvTitle = 'Peach'
            break;
        case 'vanila':
            Icon = <GiVanillaFlower />;
            bgColor = 'flvVanilla'
            flvTitle = 'Vanilla'
            break;
        default:
            break;
    }

    return (
        <div className='flavourCircle'>
            <span className={`${bgColor}`} title={flvTitle}>{Icon}</span>
        </div>
    )
}

export default Flavour