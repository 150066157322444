import HoneyDewGreenMilkTea from '../assets/images/honeydew_green_milk_tea.png';
import MangoFruitGreenTea from '../assets/images/mango_fruit_green_tea.png';
import MotchaGreenMilkTea from '../assets/images/matcha_green_milk_tea.png';
import TaroSmoothie from '../assets/images/taro_smoothie.png';
import ThaiTea from '../assets/images/thai_tea.png';
import VietnameseIcedCoffee from '../assets/images/vietnamese_iced_coffee.png';
import CoconutMilkSmoothie from '../assets/images/coconut_milk_smoothie.png';
import MatchaGreenMilkTea from '../assets/images/matcha_green_milk_tea.png';
import StraberryMangoSmoothieWithMangoJellies from '../assets/images/straberry_mango_smoothie_with_mango_jellies.png';
import StrawberryFruitBlackTeaWithMangoePoppers from '../assets/images/strawberry_fruit_black_tea_with_mangoe_poppers.png';
import BlackTea from '../assets/images/Black_Tea.png';
import GreenTea from '../assets/images/GreenTea.png';
import Poppers1 from '../assets/images/strawberryBoba.png';
import Poppers2 from '../assets/images/mangoBoba.png';
import Poppers3 from '../assets/images/pineAppleBoba.png';
import Poppers4 from '../assets/images/BlackBoba.png';

export const SliderData = [
    {
        id: 1,
        title: 'HoneyDew Green Milk Tea',
        flavour: ['strawberry', 'lemon', 'orange', 'peach', 'vanila'],
        img: HoneyDewGreenMilkTea
    },
    {
        id: 2,
        title: 'Mango Fruit Green Tea',
        flavour: ['lemon', 'orange', 'strawberry', 'peach', 'vanila'],
        img: MangoFruitGreenTea
    },
    {
        id: 3,
        title: 'Motcha Green Milk Tea',
        flavour: ['peach', 'vanila', 'strawberry', 'lemon', 'orange'],
        img: MotchaGreenMilkTea
    },
    {
        id: 4,
        title: 'Taro Smoothie',
        flavour: ['peach', 'vanila', 'strawberry', 'lemon', 'orange'],
        img: TaroSmoothie
    },
    {
        id: 6,
        title: 'Vietnamese Iced Coffee',
        flavour: ['lemon', 'peach', 'vanila', 'strawberry', 'orange'],
        img: VietnameseIcedCoffee
    },
    {
        id: 7,
        title: 'Coconut Milk Smoothie',
        flavour: ['peach', 'vanila', 'strawberry'],
        img: CoconutMilkSmoothie
    }
];

export const MenuData = [
    {
        id: 1,
        title: 'HoneyDew Green Milk Tea',
        img: HoneyDewGreenMilkTea
    },
    {
        id: 2,
        title: 'Mango Fruit Green Tea',
        img: MangoFruitGreenTea
    },
    {
        id: 3,
        title: 'Motcha Green Milk Tea',
        img: MotchaGreenMilkTea
    },
    {
        id: 4,
        title: 'Taro Smoothie',
        img: TaroSmoothie
    },
    {
        id: 5,
        title: 'Thai Tea',
        img: ThaiTea
    },
    {
        id: 6,
        title: 'Vietnamese Iced Coffee',
        img: VietnameseIcedCoffee
    },
    {
        id: 7,
        title: 'Coconut Milk Smoothie',
        img: CoconutMilkSmoothie
    },
    {
        id: 8,
        title: 'Matcha Green Milk Tea',
        img: MatchaGreenMilkTea
    },
    {
        id: 9,
        title: 'Straberry Mango Smoothie',
        img: StraberryMangoSmoothieWithMangoJellies
    },
    {
        id: 10,
        title: 'Strawberry Fruit Black Tea',
        img: StrawberryFruitBlackTeaWithMangoePoppers
    }
];


export const CustomerReview = [
    {
        id: 1,
        rating: 5,
        name: 'Aspen Cyborg',
        message: "Delicious drinks! The service was fast and the people working there are very nice. They have so many flavors to choose from! I recommend giving this place a try if you're a fan of Boba tea! It's amazing!"
    },
    {
        id: 2,
        rating: 5,
        name: 'Deanera Arboleda',
        message: "I recently visited this boba tea place, and it was an absolute delight! Their menu was offering a wide variety of flavors and customizable options. The staff was friendly and knowledgeable, helping me choose the perfect drink."
    },
    {
        id: 3,
        rating: 5,
        name: 'Anastasia Winkle',
        message: "They are the best place for drinks me and my family has ever gone to. The workers are so nice. They make the orders quickly and overall great drinks! My brother doesn't like bubble tea but he loves their slushes."
    },
    {
        id: 4,
        rating: 5,
        name: 'Brooke Anderson',
        message: "I come to the mall just to get this bubble tea. Every time I'm in town I make it a point to stop by. I have yet to find another place that begins to compare to Poppers. By far my favorite bubble tea spot."
    },
    {
        id: 5,
        rating: 5,
        name: 'Katie Snode',
        message: "First time there and not disappointed at all. Only 2nd time ordering a milk tea and I can't get enough! 10/10 will get again and would recommend.  And the worker was so sweet and answered all of my questions!"
    },
    {
        id: 6,
        rating: 5,
        name: 'Amelia Bowen',
        message: "I LOVED it! I didn't know what to get and so i asked the girl at the register and this is what she gave me. it's not too sugary and it's not bland, it's just perfect. the price was more than ideal for what you get!!"
    },
]


export const Toppings = [
    {
        id: 1,
        name: 'Green Tea',
        image: GreenTea
    },
    {
        id: 2,
        name: 'Pineapple',
        image: Poppers2
    },
    {
        id: 3,
        name: 'Mango',
        image: Poppers3
    },
    {
        id: 4,
        name: 'Boba',
        image: Poppers4
    },
    {
        id: 5,
        name: 'Strawberry',
        image: Poppers1
    },
    {
        id: 6,
        name: 'Black Tea',
        image: BlackTea
    }
]