import React from 'react';
import './Location.css';
import { motion } from 'framer-motion';

const Location = () => {
    return (
        <div className="location-container">
            <div className="location-header">
                <motion.h1
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    className='location-title'
                >
                    Location
                </motion.h1>
            </div>
            <motion.div className="location-map"
                initial={{ scale: 1 }}
                animate={{ scale: 1.1 }}
                transition={{ duration: 1 }}
            >
                <iframe
                    title="Popper's Location"
                    src="https://www.google.com/maps/d/u/9/embed?mid=1dk26ze0QBZnO2Vu4BghncPcPYNyw3xo&ehbc=2E312F&noprof=1&authuser=0"
                ></iframe>
            </motion.div>
        </div>
    );
}

export default Location;