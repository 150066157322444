import React from 'react';
import { motion } from 'framer-motion';
import './HeroSection.css';
import HeroBanner from '../../assets/images/hero-banner-2.png';
import PoppersLogo from '../../assets/images/PoppersTeaLogo.png';

const HeroSection = () => {
    return (
        <div className='hero-container'>
            <div className='logo-container'>
                <img src={PoppersLogo} alt="popper-tea" />
            </div>
            <div className='hero-box'>
                <motion.div className='hero-left'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                >
                    <div className='hero-img-background'>
                        <img src={HeroBanner} alt="Hero Banner" />
                        <div className='blur-shading'></div>
                    </div>
                </motion.div>

                <div className='hero-right'>
                    <motion.h1 className='hero-title'
                        initial={{ y: 100, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.5, duration: 1 }}
                    >
                        Popper's &nbsp;Tea
                    </motion.h1>
                    <motion.p className='hero-sub-title'
                        initial={{ y: 100, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.7, duration: 1 }}
                    >
                        Poppers tea flavor of the world.
                    </motion.p>
                    <motion.p className='hero-desc'
                        initial={{ y: 100, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.9, duration: 1 }}
                    >
                        Explore our wide selection of premium teas at Popper's Tea. From classic blends to exotic flavors, find your perfect cup of tea.
                    </motion.p>
                    <motion.div className="hero-right-btn"
                        initial={{ y: 100, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 1.2, duration: 1 }}
                    >
                        <a href="/menu">
                            Explore more
                            <div className="mouse-scroll">
                                <div className='arrow-span'>
                                    <span className="down-arrow-1"></span>
                                    <span className="down-arrow-2"></span>
                                    <span className="down-arrow-3"></span>
                                </div>
                            </div>
                        </a>
                    </motion.div>
                </div>
            </div>
        </div>
    );
}

export default HeroSection;