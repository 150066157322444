import React from 'react';
import './Home.css';
import HeroSection from '../../sections/HeroSection/HeroSection';
import SliderSection from '../../sections/SliderSection/SliderSection';
import ReviewSection from '../../sections/ReviewSection/ReviewSection';

const Home = () => {
    return (
        <div className='home-container'>
            <HeroSection />

            <SliderSection />

            <ReviewSection />
        </div>
    )
}

export default Home;