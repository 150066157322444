import React, { useEffect, useState } from 'react';
import './SliderSection.css';
import Flavour from '../../components/customcomponents/Flavour/Flavour';
import DoodleDrink1 from '../../assets/images/doodle-drink-1.png';
import DoodleDrink2 from '../../assets/images/doodle-drink-2.png';
import DoodleDrink3 from '../../assets/images/doodle-drink-3.png';
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { SliderData } from '../../data/data';

const SliderSection = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [transitioning, setTransitioning] = useState(false);

    const nextSlide = () => {
        setTransitioning(true);
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % SliderData.length);
            setTransitioning(false);
        }, 500);
    };

    const prevSlide = () => {
        setTransitioning(true);
        setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + SliderData.length) % SliderData.length);
            setTransitioning(false);
        }, 500);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide();
        }, 4000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className='slider-container'>
            <div className='slider-box'>
                <img className='doddle doddle-1' src={DoodleDrink1} alt="doddle" />

                <div className={`slider-box-left ${transitioning ? 'fade-out' : ''}`}>
                    <img
                        src={SliderData[currentIndex].img}
                        alt="img"
                    />
                </div>

                <img className='doddle doddle-2' src={DoodleDrink2} alt="" />

                <div className={`slider-box-middle ${transitioning ? 'fade-out' : ''}`}>
                    <h2 className='slider-title'>{SliderData[currentIndex].title}</h2>
                    {
                        SliderData[currentIndex].flavour && (
                            <div className='product-flv'>
                                {SliderData[currentIndex].flavour.map((flv, index) => (
                                    <Flavour key={index} flv={flv} />
                                ))}
                            </div>
                        )
                    }
                </div>

                <img className='doddle doddle-3' src={DoodleDrink3} alt="" />

                <div className='slider-box-right'>
                    <button className='next-btn slider-btn' onClick={nextSlide}><MdOutlineKeyboardDoubleArrowRight /></button>
                    <button className='prev-btn slider-btn' onClick={prevSlide}><MdOutlineKeyboardDoubleArrowLeft /></button>
                </div>
            </div>
        </div>
    );
}

export default SliderSection;
