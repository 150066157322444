import React, { useState } from 'react'
import './Navbar.css'
import { Link } from 'react-router-dom'
import LogoImg from '../../../assets/images/poppers-tea-logo.png';
import { TfiMenuAlt } from "react-icons/tfi";

const Navbar = () => {
    const [navbarOpen, setNavbarOpen] = useState(false);

    const handleNavbar = () => {
        setNavbarOpen(!navbarOpen);
    }

    return (
        <div className={`navbar-container ${navbarOpen ? 'no-radius' : ''}`}>
            <div className="logo">
                <Link to="/">
                    <img src={LogoImg} alt='tea-shop' />
                </Link>
            </div>

            <div className='hamburger-menu'>
                <TfiMenuAlt onClick={handleNavbar} />
            </div>

            <div className={`navlinks ${navbarOpen ? 'active-navbar' : ''}`}>
                <Link to='/' onClick={handleNavbar}>Home</Link>
                <Link to='/menu' onClick={handleNavbar}>Menu</Link>
                <Link to='/about' onClick={handleNavbar}>About</Link>
                <Link to='/contact' onClick={handleNavbar}>Contact</Link>
                <Link to='/location' onClick={handleNavbar}>Location</Link>
            </div>
        </div>
    )
}

export default Navbar;